import React from "react";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from "../../assets/image/mobileCarouselImg/imgparacelu1.jpeg";
import img2 from "../../assets/image/mobileCarouselImg/imgparacelu2.jpeg";
import img3 from "../../assets/image/mobileCarouselImg/imgparacelu3.jpeg";
import img4 from "../../assets/image/mobileCarouselImg/imgparacelu4.jpeg";

const MobileCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {isMobile && (
        <Slider {...settings}>
          <div style={{ textAlign: "center" }}>
            <img
              src={img1}
              alt="Imagen 1"
              style={{ width: "95%", borderRadius: "5px" }}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <img
              src={img2}
              alt="Imagen 2"
              style={{ width: "95%", borderRadius: "5px" }}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <img
              src={img3}
              alt="Imagen 3"
              style={{ width: "95%", borderRadius: "5px" }}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <img
              src={img4}
              alt="Imagen 4"
              style={{ width: "95%", borderRadius: "5px" }}
            />
          </div>
        </Slider>
      )}
    </>
  );
};

export default MobileCarousel;
