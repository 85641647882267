import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import Carousel from "../Carousel/Carousel";
import NavigationButtons from "../NavigationButtons/NavigationButtons";
import { ReactComponent as MercadoLibreIcon } from "../../assets/logoML/ml.svg";

const VehiculoDetalle = () => {
  const { vehicleType, vehicleName } = useParams();
  const [imageUrls, setImageUrls] = useState([]);
  const [vehicleData, setVehicleData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storage = getStorage();
    const folderRef = ref(storage, `Autos/${vehicleType}/${vehicleName}`);

    listAll(folderRef)
      .then((res) => {
        return Promise.all(
          res.items.map((item) => {
            return getDownloadURL(item).then((url) => {
              return url;
            });
          })
        );
      })
      .then((urls) => {
        setImageUrls(urls.filter((url) => !url.includes(".json")));
        const jsonUrl = urls.find((url) => url.includes(".json"));
        if (jsonUrl) {
          fetch(jsonUrl)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then((data) => {
              setVehicleData(data);
            })
            .catch((error) => {
              console.error("Error fetching JSON:", error);
            });
        }
        setIsLoading(false);
      })
      .catch(console.error);
  }, [vehicleType, vehicleName]);

  return (
    <div className="container mx-auto px-4">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          {vehicleData && vehicleData.Marca && (
            <h2 className="text-center mt-4 text-2xl sm:text-3xl md:text-5xl font-bold">
              {vehicleData.Marca}
            </h2>
          )}
          {vehicleData && vehicleData.Modelo && (
            <h3 className="text-center mt-2 mb-2 text-xl sm:text-2xl md:text-4xl font-bold">
              {vehicleData.Modelo}
            </h3>
          )}
          <div className="flex flex-wrap justify-center items-center mt-4 text-center ">
            <div className="w-full sm:w-4/5 lg:w-3/5 max-w-lg mx-auto max-h-500px overflow-hidden ">
              <Carousel
                items={imageUrls.map((url) => (
                  <img
                    key={url}
                    src={url}
                    alt=""
                    className="object-cover w-full h-full rounded-xl"
                  />
                ))}
              />
            </div>
          </div>
          {vehicleData && vehicleData.Año && (
            <p className="text-center mt-4 text-lg sm:text-base md:text-xl font-semibold">
              Año: {vehicleData.Año}
            </p>
          )}
          {vehicleData && vehicleData.Kilometros && (
            <p className="text-center mt-4 text-lg sm:text-base md:text-xl font-semibold">
              Kilometraje: {vehicleData.Kilometros}
            </p>
          )}
          {vehicleData && vehicleData.Observaciones && (
            <p className="text-center mt-4 text-lg sm:text-base md:text-xl font-semibold">
              Información Adicional: {vehicleData.Observaciones}
            </p>
          )}
          {vehicleData && vehicleData.urlMercadoLibre && (
            <p className="text-center text-lg sm:text-base md:text-xl font-semibold align-items: center">
              <a
                href={vehicleData.urlMercadoLibre}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center"
              >
                <MercadoLibreIcon className="w-16 h-16 sm:w-16 sm:h-16 md:w-[120px] md:h-[120px] md:mt-5" />
                <span className="flex items-center leading-none">
                  Ver Publicación en Mercado Libre
                </span>
                <MercadoLibreIcon className="w-16 h-16 sm:w-16 sm:h-16 md:w-[120px] md:h-[120px] md:mt-5" />
              </a>
            </p>
          )}
          <NavigationButtons />
        </>
      )}
    </div>
  );
};

export default VehiculoDetalle;
