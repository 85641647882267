import React from "react";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from "../../assets/image/desktopCarrousel/1.png";
import img2 from "../../assets/image/desktopCarrousel/2.png";
import img3 from "../../assets/image/desktopCarrousel/3.png";
import img4 from "../../assets/image/desktopCarrousel/4.png";

const DesktopCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const isDesktop = useMediaQuery({ minWidth: 767 });

  return (
    <>
      {isDesktop && (
        <Slider {...settings}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "50%", // Achica el div al 75% del tamaño del contenedor padre
              margin: "0 auto", // Centra el div horizontalmente
            }}
          >
            <img
              src={img1}
              alt="Imagen 1"
              style={{
                width: "90%", // La imagen ocupará todo el ancho del div, que ya está reducido al 75%
                borderRadius: "5px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
              margin: "0 auto",
            }}
          >
            <img
              src={img2}
              alt="Imagen 2"
              style={{
                width: "90%",
                borderRadius: "5px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
              margin: "0 auto",
            }}
          >
            <img
              src={img3}
              alt="Imagen 3"
              style={{
                width: "90%",
                borderRadius: "5px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
              margin: "0 auto",
            }}
          >
            <img
              src={img4}
              alt="Imagen 4"
              style={{
                width: "90%",
                borderRadius: "5px",
              }}
            />
          </div>
        </Slider>
      )}
    </>
  );
};

export default DesktopCarousel;
